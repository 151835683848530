/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/** 前十大交易人選擇權留倉口數 */
export const large_trader_options_top10 = createIndicator({
    id: 'largetraderoptionstop10',
    displayName: '前十大交易人選擇權留倉口數',
    enabledOn(symbol, data, channel) {
        return data?.type === channel.tw_futures;
    },
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#large_trader_options_top10';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            this._context.select_sym(1);
            const o = this.PineJS.Std.open(this._context);
            const o_pos = o > 0 ? o : NaN;
            const o_neg = o < 0 ? o : NaN;
            return [o_pos, o_neg];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#007f00',
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '前十大交易人選擇權留倉口數',
                histogramBase: 0,
            },
            plot_1: {
                title: '前十大交易人選擇權留倉口數',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
export const add_large_trader_options_top10 = createIndicator({
    id: 'addlargetraderoptionstop10',
    displayName: '前十大交易人選擇權按契約累加口數',
    enabledOn(symbol, data, channel) {
        return data?.type === channel.tw_futures;
    },
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#large_trader_options_top10';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            this._context.select_sym(1);
            //日期
            const time = this.PineJS.Std.time(this._context);
            //日期處理
            const getDay = new Date(time).getDay();
            const getDayArray = this._context.new_var(getDay);
            const getMonth = new Date(time).getMonth();
            //月份比較
            const monthCompare = this._context.new_var();
            //每月幾號
            const MonthOfDay = new Date(time).getDate();
            //籌碼
            const chipsDiff = this.PineJS.Std.close(this._context);
            const sumChipsDiff = this._context.new_var();
            //月結算FLAG
            const checkDayLine = this._context.new_var();
            //月結算的標記
            const mark = this._context.new_var();
            //儲存當日月份
            monthCompare.set(getMonth);
            //換月時將結算標記歸零
            if (monthCompare.get(0) !== monthCompare.get(1)) {
                mark.set(0);
            }
            //月結算邏輯 , 當月結Mark = 0 時才會判斷
            const checkDay3 = mark.get(0) === 0 && getDay === 3 && MonthOfDay >= 15 && MonthOfDay <= 21;
            const checkDay4 = mark.get(0) === 0 && getDay === 4 && MonthOfDay >= 16 && MonthOfDay <= 22;
            const checkDay5 = mark.get(0) === 0 && getDay === 5 && MonthOfDay >= 17 && MonthOfDay <= 23;
            const checkDay1 = mark.get(0) === 0 && getDay === 1 && MonthOfDay >= 20 && MonthOfDay <= 26;
            const checkDay2 = mark.get(0) === 0 && getDay === 2 && MonthOfDay >= 21 && MonthOfDay <= 27;
            const isCheckDay = checkDay3 || checkDay4 || checkDay5 || checkDay1 || checkDay2;
            //如果是月結算 月結Mark標記為1 ,然後將在下次換月時 Mark標記才會歸零
            if (isCheckDay) {
                sumChipsDiff.set(chipsDiff);
                checkDayLine.set(1000000000);
                mark.set(1);
            }
            else {
                sumChipsDiff.set(sumChipsDiff.get(1) + chipsDiff);
                checkDayLine.set(NaN);
            }
            //累積籌碼Plot邏輯
            const value = this.PineJS.Std.sma(sumChipsDiff, 1, this._context);
            const valueRed = value > 0 ? value : NaN;
            const valueGreen = value < 0 ? value : NaN;
            //月結算線
            const valueLine = this.PineJS.Std.sma(checkDayLine, 1, this._context);
            //
            return [valueRed, valueGreen, valueLine, -valueLine];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
            {
                id: 'plot_2',
                type: 'histogram',
            },
            {
                id: 'plot_3',
                type: 'histogram',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 40,
                    color: '#007f00',
                },
                plot_2: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 1,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 40,
                    color: '#999999',
                },
                plot_3: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 1,
                    plottype: 1,
                    trackPrice: false,
                    transparency: 40,
                    color: '#999999',
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '前十大交易人選擇權按契約累加口數',
                histogramBase: 0,
            },
            plot_1: {
                title: '前十大交易人選擇權按契約累加口數',
                histogramBase: 0,
            },
            plot_2: {
                title: '月結算',
                histogramBase: 0,
            },
            plot_3: {
                title: '月結算',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});
